
import { graphql } from "gatsby"
import React from 'react';
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"

export const fragment = graphql`
  fragment HeaderAndListFragment on WpPage_Flexlayouts_FlexibleLayouts_HeaderAndList
   {  
      description
      title
      headerAndList {
        text
      }
}
`
const HeaderAndList = ({description,title,headerAndList}) => {


  return (
    <section className="section">
      <Container >
        <Row>
          <Col className="mx-auto">
            {description && <p className={`pt-4 pb-2 h2 font-weight-normal`} dangerouslySetInnerHTML={{ __html: description }} />}
            {title && <p className={`font-weight-bold py-4`} dangerouslySetInnerHTML={{ __html: title }} />}
            {headerAndList.map((item, i) => {
              return (
                <p className="pb-4"  key={i} dangerouslySetInnerHTML={{ __html: item.text }} />
              )
            })}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HeaderAndList
